import React,{ createContext, useState } from "react"

let languageNavigator = window.navigator.language;
let language = 'ptbr'
if (languageNavigator !== 'pt-BR'){
  language = 'en'
}
let localStorageLang = localStorage.getItem('idlang')
let initialIdLang = localStorageLang ? localStorageLang : language


//Função que constroe o Provider e támbem permite Consumir os Dados Globais
export const LangContext = createContext()

//Componente Provider para passar os valores para os Childrens
function LangProvider ({children}) {

  const [lang, setLang] = useState(initialIdLang)

  return (
    <LangContext.Provider value={{lang, setLang}}>  
      {children}
    </LangContext.Provider>
  )
}

export default LangProvider;