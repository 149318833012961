import React from "react";
import About from "../components/pages/about";

const AboutScreen = () => {
  return (
    <>
      <About/>
    </>
  )
};

export default AboutScreen ;