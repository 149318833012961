import React from "react";
import Home from "../components/pages/home";

const HomeScreen = () => {

  return (
    <>
      <Home/>
    </>
  )
};

export default HomeScreen;