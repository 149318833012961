import React from 'react';
import './App.css';

import Rotas from './routes';



function App() {

  return (
    <div className="App">
      <Rotas/>
    </div>
  );
}

export default App;
