import React from "react";
import Portfolio from "../components/pages/portifolio";

const PortfolioScreen = () => {
  return (
    <>
      <Portfolio/>
    </>

  )
};

export default PortfolioScreen;