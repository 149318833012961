import React, { useContext, useEffect, useState } from "react";
import { LangContext } from "../../contexts/LangContext";
import getInfos from "../../utils/getInfos";
import './index.css';

const InfoContact = () => {
  const { lang } = useContext(LangContext)
  const [infos, setInfos] = useState(null)
  useEffect(() => {
    getInfos(lang, 'infoContact').then((data) => {
      setInfos(data);
    }, error => {

    });
  }, [lang]);

  return (
    <div className="container-fluid main-info-contact">
      <div className="container-fluid info-contact p-5 ">
        {infos && (
          <>
            <div >
              <h5>{infos.contact.title}</h5>
              <p>{infos.contact.phone}</p>
              {
                infos.contact.emails.map((e, i) => {
                  return (<p><a key={i} href={`mailto:${e}`} data-type="mailto" data-id={`mailto:${e}`}>{e}</a></p>);
                })
              }
            </div>

            <div>
              <h5>{infos.social.title}</h5>
              {
                infos.social.networks.map((n, i) => {
                  return (
                    <p>
                      <a href={n.link} key={i} target="_blank" rel="noreferrer">
                        {n.name}
                        <img
                          alt="svgImg"
                          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCI+CjxwYXRoIGZpbGw9IiMwMjg4ZDEiIGQ9Ik0yNCA0QTIwIDIwIDAgMSAwIDI0IDQ0QTIwIDIwIDAgMSAwIDI0IDRaIj48L3BhdGg+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE0IDE5SDE4VjM0SDE0ek0xNS45ODggMTdoLS4wMjJDMTQuNzcyIDE3IDE0IDE2LjExIDE0IDE0Ljk5OSAxNCAxMy44NjQgMTQuNzk2IDEzIDE2LjAxMSAxM2MxLjIxNyAwIDEuOTY2Ljg2NCAxLjk4OSAxLjk5OUMxOCAxNi4xMSAxNy4yMjggMTcgMTUuOTg4IDE3ek0zNSAyNC41YzAtMy4wMzgtMi40NjItNS41LTUuNS01LjUtMS44NjIgMC0zLjUwNS45MjgtNC41IDIuMzQ0VjE5aC00djE1aDR2LThjMC0xLjY1NyAxLjM0My0zIDMtM3MzIDEuMzQzIDMgM3Y4aDRDMzUgMzQgMzUgMjQuOTIxIDM1IDI0LjV6Ij48L3BhdGg+Cjwvc3ZnPg=="
                        />
                      </a>
                    </p>
                  );
                })
              }
            </div>
          </>
        )}
      </div>
    </div>

  )
}

export default InfoContact;