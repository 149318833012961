import React from "react";
import Expertise from "../components/pages/expertise";


const ExpertiseScreen = () => {
  return (
    <>
      <Expertise/>
    </>

  )
};

export default ExpertiseScreen;