import React from "react";
import Form from "../components/pages/form";
import InfoContact from "../components/infocontact";


const FormScreen = () => {
  return (
    <>
      <Form/>
      <InfoContact/>
    </>
  )
};

export default FormScreen;