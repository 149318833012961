import content from './content.json';


async function getInfos(idlang, page) {
  // let response = await fetch(`/content/${idlang}/${page}`, 
  //  {  method: 'GET', mode: 'no-cors'});
  // let data = await response.json();
  console.log(content[idlang][page]);
  let data = content[idlang][page];
  return data;
}

export default getInfos;