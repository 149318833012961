import React, { useContext, useEffect, useState } from "react";
import { LangContext } from "../../../contexts/LangContext";
import getInfos from "../../../utils/getInfos";
import { postForm } from "../../../utils/postForm";
import './index.css';
import imgBadge from "../../../assets/img/contact/badge.png";
import imgFlow from "../../../assets/img/contact/flow.png";
import imgLvaEn from "../../../assets/img/contact/lva_en.png";
import imgLvaPt from "../../../assets/img/contact/lva_pt.png";
import imgRd from "../../../assets/img/contact/rd.png";
import imgSc from "../../../assets/img/contact/sc.png";


const initialState = {
  name: '',
  phone: '',
  email: '',
  message: ''
}

const initialPopup = { popMain: "popup-close", popContent: "popup-close" }
const openPopup = { popMain: "popup-container", popContent: "popup-contente" }

const Form = () => {
  const { lang } = useContext(LangContext)
  const [infos, setInfos] = useState({})
  useEffect(() => {
    getInfos(lang, 'form').then((data) => {
      console.log(data.servicesDescription)
      setInfos(data);
    }, error => {
    });
  }, [lang]);

  const [fields, setFields] = useState(initialState)
  const handleFieldesChange = (event) => setFields({
    ...fields,
    [event.currentTarget.name]: event.currentTarget.value,
  });

  const [popup, setPopup] = useState(initialPopup)

  const handleSubmit = (event) => {
    console.log(fields)
    postForm(fields)
    setPopup(openPopup)
    event.preventDefault()
  }

  const closePopup = () => {
    setPopup(initialPopup)
    setFields(initialState)
  }

  return (
    <div className="container-fluid main-content-contact">
      {infos?.servicesDescription && <>
        <div className="container container-margin-and-padding">
          <h4>{infos.servicesDescription.title}</h4>
          <br/>
          <h5>{infos.servicesDescription.subtitle}</h5>
          <div className="d-flex flex-column mt-5">
            <div className="flex-container">
              <span className="span-item">{infos.servicesDescription.cycle.text}</span>
              <img src={lang === 'ptbr' ? imgLvaPt : imgLvaEn} alt={infos.servicesDescription.cycle.alt} className="img-item" />
            </div>
            <div className="flex-container">
              <span className="span-item">{infos.servicesDescription.flow.text}</span>
              <img src={imgFlow} alt={infos.servicesDescription.flow.alt} className="img-item" />
            </div>
            <div className="flex-container">
              <span className="span-item">{infos.servicesDescription.badge.text}</span>
              <img src={imgBadge} alt={infos.servicesDescription.badge.alt} className="img-item" />
            </div>
            <div className="flex-container">
              <span className="span-item">{infos.servicesDescription.cs.text}</span>
              <img src={imgSc} alt={infos.servicesDescription.cs.alt} className="img-item" />
            </div>
            <div className="flex-container">
              <span className="span-item">{infos.servicesDescription.rd.text}</span>
              <img src={imgRd} alt={infos.servicesDescription.rd.alt} className="img-item" />
            </div>
            <p>{infos.servicesDescription.cloud}</p>
          </div>
        </div>
        <div className="container m-5 p-5 form contente">
        <h3 className="mb-3">{infos.formItems?.button}</h3>
          <form id="formulario" onSubmit={handleSubmit} >
            <div className="mb-3" >
              <label htmlFor="name" className="form-label"><strong>{infos.formItems?.name}</strong> *</label>
              <input type="text" className="form-control" id="name" name="name" value={fields.name} required onChange={handleFieldesChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label"><strong>{infos.formItems?.phone}</strong> *</label>
              <input type="text" className="form-control" id="phone" name="phone" value={fields.phone} required onChange={handleFieldesChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label"><strong>{infos.formItems?.email}</strong> *</label>
              <input type="email" className="form-control" id="email" name="email" value={fields.email} aria-describedby="emailHelp" required onChange={handleFieldesChange} />
              <div id="emailHelp" className="form-text"></div>
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label"><strong>{infos.formItems?.message}</strong></label>
              <textarea name="message" id="message" cols="30" rows="7" className="form-control" value={fields.message} onChange={handleFieldesChange}></textarea>
            </div>
            <button type="submit" className="btn btn-color">{infos.formItems?.button}</button>
          </form>
        </div>
        <div id="div-popup" className={popup.popMain}>
          <div className={popup.popContent}>
            <p id="text-popup">{fields.name} {infos.formItems?.textPopup}</p>
            <button id="btn-close-popup" className="btn btn-outline-success" onClick={closePopup}>X</button>
          </div>
        </div>
      </>}
    </div>
  )
}

export default Form;